import { IconProps } from "./props"

export const LogoText = ({ className, style }: IconProps) => {
  return (
    <svg
      className={className}
      width="120"
      height="14"
      viewBox="0 0 120 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#clip0_1473_9097)">
        <path
          d="M-0.00390625 6.03021C-0.00390625 2.43852 2.65599 -1.95214e-07 5.99326 -1.95214e-07C7.36443 -0.0082152 8.72316 0.260368 9.98807 0.789694V3.59169H9.72373C9.02654 1.021 7.61564 0.327115 5.95692 0.327115C3.38954 0.327115 1.88611 2.50459 1.88611 5.67004C1.88611 8.7694 3.42257 11.1914 6.31377 11.1914C7.99232 11.1914 9.20497 10.5305 10.2788 8.80244L10.5267 8.93462C9.7799 10.5471 8.42186 11.9976 5.90074 11.9976C2.59652 11.9976 0.0126108 9.60868 0.0126108 6.03021"
          fill="white"
        />
        <path
          d="M22.2968 8.5216L22.0457 11.7697H12.8203V11.5516L13.1507 11.502C13.7389 11.4062 14.2411 11.274 14.2411 10.5966V1.401C14.2411 0.740156 13.7389 0.545191 13.1507 0.475802L12.8203 0.442768V0.231293H21.6558V2.98041H21.4212C21.0478 1.3283 20.3903 0.578252 18.3879 0.578252H15.8569V5.67005H17.3405C18.6622 5.67005 19.1942 5.20747 19.4849 4.18646H19.6964V7.64928H19.4915C19.2008 6.62828 18.6688 6.01699 17.3471 6.01699H15.8569V10.6429C15.8569 11.3368 16.3228 11.4326 17.0167 11.4326H18.2491C20.6414 11.4326 21.3915 10.7916 22.0523 8.53481L22.2968 8.5216Z"
          fill="white"
        />
        <path
          d="M24.6094 11.5549L24.9398 11.5053C25.5313 11.4062 26.0302 11.274 26.0302 10.5966V1.401C26.0302 0.740156 25.5313 0.54189 24.9398 0.47911L24.6094 0.446075V0.231293H29.84C33.4085 0.231293 35.9065 2.40549 35.9065 5.96744C35.9065 9.5294 33.4085 11.7664 29.84 11.7664H24.6094V11.5549ZM29.8036 11.4227C32.447 11.4227 34.0132 9.44017 34.0132 6.3309C34.0132 3.07954 32.5527 0.574945 29.4633 0.574945H27.6625V10.6165C27.6625 11.241 28.1779 11.4227 28.819 11.4227H29.8036Z"
          fill="white"
        />
        <path
          d="M47.7875 8.5216L47.5364 11.7697H38.3242V11.5516L38.6546 11.502C39.2461 11.4062 39.745 11.274 39.745 10.5966V1.401C39.745 0.740156 39.2461 0.545191 38.6546 0.475802L38.3242 0.442768V0.231293H47.1564V2.98041H46.9152C46.5385 1.3283 45.881 0.578252 43.8819 0.578252H41.3641V5.67005H42.8477C44.1694 5.67005 44.7014 5.20747 44.9888 4.18646H45.2036V7.64928H44.9888C44.7014 6.62828 44.1661 6.01699 42.8477 6.01699H41.3641V10.6429C41.3641 11.3368 41.8267 11.4326 42.5239 11.4326H43.7564C46.1486 11.4326 46.8987 10.7916 47.5595 8.53481L47.7875 8.5216Z"
          fill="white"
        />
        <path
          d="M61.3263 11.5516V11.7697H58.6829C58.4847 11.5218 57.8238 10.7784 57.1993 9.93911L55.0813 7.09417C54.5824 6.41681 54.4039 6.30115 53.9215 6.30115H53.1021V10.5966C53.1021 11.2575 53.568 11.3897 54.3346 11.502L54.665 11.5516V11.7697H50.0391V11.5516L50.3695 11.502C50.9609 11.4029 51.4599 11.274 51.4599 10.5966V1.401C51.4599 0.740156 50.9609 0.541887 50.3695 0.475802L50.0391 0.446075V0.231293H55.0549C57.0374 0.231293 59.2645 0.987962 59.2645 3.16545C59.2645 4.89356 57.8403 5.83526 56.1816 6.13925L58.6234 9.33445C59.7667 10.818 60.3383 11.3797 61.052 11.5086L61.3263 11.5516ZM54.7178 5.93439C56.4856 5.93439 57.6454 5.21077 57.6454 3.31745C57.6454 1.20605 56.2147 0.565023 54.5394 0.565023H53.112V5.93439H54.7178Z"
          fill="white"
        />
        <path
          d="M69.8451 11.9051C69.4653 11.9607 69.0823 11.9916 68.6985 11.9976C65.4505 11.9646 62.8633 9.57564 62.8633 6.03021C62.8633 2.48478 65.4835 0 68.7844 0C72.0854 0 74.7122 2.39225 74.7122 5.96412C74.7122 8.85201 73.0138 10.9931 70.6414 11.7201C70.3827 11.8076 70.116 11.8696 69.8451 11.9051ZM68.91 11.6705C71.223 11.6705 72.8189 9.49302 72.8189 6.32758C72.8189 3.04648 71.3221 0.330422 68.6589 0.330422C66.3459 0.330422 64.7533 2.50459 64.7533 5.67004C64.7533 8.93131 66.2699 11.6705 68.91 11.6705Z"
          fill="white"
        />
        <path
          d="M78.5263 7.82771V1.40101C78.5263 0.740168 78.0273 0.541898 77.4359 0.475814L77.1055 0.446087V0.227997H81.6554V0.446087L81.325 0.475814C80.684 0.541898 80.1487 0.723647 80.1487 1.40101V7.69886C80.1487 10.3422 81.629 11.4062 83.5752 11.4062C85.4123 11.4062 86.7505 10.448 86.7505 7.91032V1.73144C86.7505 0.806252 86.2681 0.561724 85.5544 0.475814L85.2504 0.446087V0.227997H88.6802V0.446087L88.3894 0.475814C87.679 0.561724 87.1933 0.806252 87.1933 1.73144V8.00946C87.1933 10.3224 85.9311 12.0142 83.0366 12.0142C79.8282 12.0142 78.5065 10.3984 78.5065 7.84425"
          fill="white"
        />
        <path
          d="M90.8398 11.5549L91.1703 11.5053C91.7617 11.4062 92.2573 11.274 92.2573 10.5966V1.40431C92.2573 0.723638 91.7617 0.54189 91.1703 0.47911L90.8398 0.446075V0.231293H95.2972V0.446075L94.9668 0.47911C94.3787 0.54189 93.8797 0.723638 93.8797 1.40431V10.5966C93.8797 11.2575 94.3787 11.4062 94.9668 11.5053L95.2972 11.5549V11.7664H90.8398V11.5549Z"
          fill="white"
        />
        <path
          d="M98.4805 11.0262L98.5862 7.76162H98.8538C99.2636 9.98867 100.46 11.6705 102.779 11.6705C104.478 11.6705 105.565 10.6792 105.565 9.24521C105.565 7.92352 104.511 7.31885 102.779 6.658L102.049 6.37715C99.9244 5.57092 98.6953 4.74486 98.6953 3.14892C98.6953 1.28533 100.516 0 102.604 0C103.783 0.00530349 104.95 0.229382 106.047 0.660845L105.974 3.49255H105.71C105.244 1.40097 104.263 0.34694 102.475 0.34694C100.926 0.34694 99.9442 1.28534 99.9442 2.45504C99.9442 3.62473 100.747 4.16993 102.674 4.91338L103.407 5.19092C105.69 6.06654 106.85 6.84304 106.85 8.53811C106.85 10.4645 105.135 12.0142 102.604 12.0142C101.173 12.0122 99.7619 11.6798 98.4805 11.0427"
          fill="white"
        />
        <path
          d="M111.828 11.5549L112.159 11.5053C112.747 11.4062 113.249 11.274 113.249 10.5966V0.578252H112.41C110.338 0.578252 109.555 1.35142 109.145 3.16214H108.914L109.145 0.231293H118.995L119.227 3.16214H118.995C118.586 1.35142 117.819 0.578252 115.747 0.578252H114.891V10.5966C114.891 11.2575 115.39 11.4062 115.982 11.5053L116.312 11.5549V11.7664H111.828V11.5549Z"
          fill="white"
        />
        <path
          d="M74.8072 12.8039C74.0572 13.1343 73.4591 12.827 72.7685 12.2851L72.3258 11.9547C71.6451 11.426 70.971 11.2575 70.254 11.5615C69.5985 11.8898 69.0707 12.4268 68.7539 13.088V13.2698C68.9425 12.9894 69.1995 12.762 69.5006 12.6089C70.254 12.2785 70.8521 12.5825 71.5394 13.1244L71.9854 13.4548C72.6628 13.9835 73.3401 14.152 74.0539 13.8513C74.7043 13.5585 75.2332 13.0494 75.5507 12.4107V12.2322C75.3628 12.4894 75.1046 12.6867 74.8072 12.8006"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1473_9097">
          <rect width="119.216" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
