import type { ImageProps } from "next/legacy/image"
import type { ACFSeoMetadata, WPGlobalFields } from "@models/common"

import classNames from "classnames"
import { ReactNode, useEffect, useRef } from "react"

import { GlobalFieldsContext } from "@contexts/acf-global"
import { ImageMapContext } from "@contexts/image-map"
import { LayoutWrapperContext } from "@contexts/layout-wrapper"

import { Footer } from "./footer"
import { GradientBG } from "./shared"

interface LayoutProps {
  globalFields: WPGlobalFields
  imageMap?: Record<number, ImageProps>
  children?: ReactNode
  enableBgAnimation?: boolean
  hasFixedMobileHeader?: boolean
  seo?: ACFSeoMetadata
}

export const Layout = ({
  globalFields,
  imageMap,
  children,
  enableBgAnimation,
  hasFixedMobileHeader,
  seo,
}: LayoutProps) => {
  const layoutWrapperRef = useRef<HTMLDivElement>(null!)

  // slide smoothly to the section indicated with the hash
  useEffect(() => {
    const callback = () => {
      // reset position to the start of the page
      window.scrollTo(0, 0)

      // scroll to the target section (if it exists)
      const hash = window.location.hash
      if (!hash) return
      const target = document.querySelector(hash)
      if (!target) return
      target.scrollIntoView({ behavior: "smooth" })
    }

    // queue action using setTimeout
    setTimeout(callback, 1)
  }, [])

  return (
    <GlobalFieldsContext.Provider value={globalFields.acfGlobalFields.acfGlobalFields}>
      <ImageMapContext.Provider value={imageMap ?? {}}>
        {/* hidden H1 for seo purposes only */}
        {seo?.metaH1 && <h1 className="pointer-events-none opacity-0">{seo.metaH1}</h1>}

        <LayoutWrapperContext.Provider value={layoutWrapperRef}>
          <div
            ref={layoutWrapperRef}
            className={classNames(
              "wrapper-layout scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white/50",
              {
                "h-[calc(100%-70px)]": hasFixedMobileHeader,
              }
            )}
          >
            {children}
            <Footer />
          </div>
        </LayoutWrapperContext.Provider>
        <GradientBG enableAnimation={enableBgAnimation} />
      </ImageMapContext.Provider>
    </GlobalFieldsContext.Provider>
  )
}
