import type { AppLocalizedTranslations, WPSeoFields } from "@models/common"

import Head from "next/head"

export interface AppHeadProps extends WPSeoFields {
  translations?: AppLocalizedTranslations | null
}

export const AppHead = ({
  title,
  metaDesc,
  opengraphTitle,
  opengraphDescription,
  opengraphImage,
  opengraphType,
  twitterTitle,
  twitterDescription,
  twitterImage,
  canonicalUrl,
  translations,
}: AppHeadProps) => {
  // page/post canonical url
  const _canonicalUrl =
    typeof canonicalUrl !== "string" || canonicalUrl.length === 0
      ? null
      : canonicalUrl.endsWith("/")
      ? canonicalUrl
      : canonicalUrl + "/"

  return (
    <Head>
      {/* SEO */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={metaDesc} />
      <meta property="og:title" content={opengraphTitle || title} />
      <meta property="og:description" content={opengraphDescription || title} />
      {opengraphImage?.mediaItemUrl && (
        <meta property="og:image" content={opengraphImage.mediaItemUrl} />
      )}
      <meta property="og:type" content={opengraphType} />
      <meta property="twitter:title" content={twitterTitle || title} />
      <meta property="twitter:description" content={twitterDescription || title} />
      {twitterImage?.mediaItemUrl && (
        <meta property="twitter:image" content={twitterImage.mediaItemUrl} />
      )}
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

      {/* SEO - canonical URL */}
      {_canonicalUrl && (
        <>
          <meta property="og:url" content={_canonicalUrl} />
          <link rel="canonical" href={_canonicalUrl} />
        </>
      )}

      {/* Localized versions metadata
        See https://developers.google.com/search/docs/specialty/international/localized-versions */}
      <AppHeadTranslations translations={translations} />

      {/* Prevent search engines from indexing this page */}
      {process.env.NEXTJS_NOINDEX === "noindex" && <meta name="robots" content="noindex" />}
    </Head>
  )
}

const AppHeadTranslations = ({ translations }: Pick<AppHeadProps, "translations">) => {
  // do not render anything if param is falsy
  if (!translations) return null

  // do not add alternate links for untranslated pages
  // i.e. translations object only consists of one item
  const entries = Object.entries(translations)
  if (entries.length < 2) return null

  // page/post translations metadata
  const linkTags = entries.map(([locale, url]) => (
    <link key={locale} rel="alternate" hrefLang={locale} href={url} />
  ))

  return <>{linkTags}</>
}
