import { useGlobalFields } from "@contexts/acf-global"
import { useResponsiveXL } from "@hooks/shared"
import { FacebookLogo } from "@icons/FacebookLogo"
import { InstagramLogo } from "@icons/InstagramLogo"
import { LinkedInLogo } from "@icons/LinkedInLogo"
import { IconLogo } from "@icons/logo"
import { LogoText } from "@icons/logo-text"

import { NewsletterSM } from "./shared"

export const Footer = () => {
  const {
    footer: {
      addressLabel,
      addressContent,
      kontaktLabel,
      kontaktContent,
      informationLabel,
      informationContent,

      socialLinks,
    },
  } = useGlobalFields()
  const isResponsiveXL = useResponsiveXL()

  // build social links
  const _socialLinks = socialLinks?.map(({ icon, link }) => (
    <a key={icon.mediaItemUrl + link} href={link} target="_blank" rel="noreferrer">
      {link.includes("linkedin") && <LinkedInLogo />}
      {link.includes("instagram") && <InstagramLogo />}
      {link.includes("facebook") && <FacebookLogo />}
    </a>
  ))

  return (
    <footer className="bg-black">
      <div className="wrapper-full flex flex-col space-y-12 pt-11 pb-[90px] lg:flex-row lg:space-y-0 lg:space-x-10 xl:space-x-20">
        <div className="flex items-center space-x-0.5 sm:space-x-3 lg:hidden">
          <IconLogo className="shrink-0 fill-white" />
          <LogoText className="shrink-0" />
        </div>
        {/* address stuff */}
        <div className="min-w-[140px] space-y-4 text-sm">
          <div>{addressLabel}</div>
          <div className="w-5 border border-white" />
          <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: addressContent }} />
        </div>

        {/* contact info */}
        <div className="min-w-[200px] space-y-4 text-sm">
          <div>{kontaktLabel}</div>
          <div className="w-5 border border-white" />
          <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: kontaktContent }} />
        </div>

        {/* information stuff */}
        <div className="space-y-4 text-sm">
          <div>{informationLabel}</div>
          <div className="w-5 border border-white" />
          <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: informationContent }} />
        </div>

        {/* newsletter form if >1280px */}
        {isResponsiveXL && (
          <NewsletterSM
            key="footer-newsletter"
            formName="Nyhetsbrev footer"
            className="max-w-[324px]"
          />
        )}

        {/* social media icons */}
        {/* and newsletter form if <1280px */}
        <div className="shrink-0 md:max-w-[375px] lg:!ml-auto lg:self-end">
          {!isResponsiveXL && <NewsletterSM formName="Nyhetsbrev footer" key="footer-newsletter" />}
          <div className="absolute right-[25px] top-[44px] flex justify-end space-x-3 lg:relative lg:top-auto lg:right-auto lg:mt-12">
            {_socialLinks}
          </div>
        </div>
      </div>
    </footer>
  )
}
