export const FacebookLogo = () => {
  return (
    <svg
      className="socialLogo rounded-full fill-white hover:fill-black"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.992 35.984C8.07133 35.984 0 27.913 0 17.992C0 8.071 8.071 0 17.992 0C27.913 0 35.984 8.071 35.984 17.992C35.984 27.913 27.9127 35.984 17.992 35.984ZM17.992 1.18633C8.725 1.18633 1.18633 8.72534 1.18633 17.992C1.18633 27.2587 8.72533 34.7977 17.992 34.7977C27.2587 34.7977 34.7977 27.2587 34.7977 17.992C34.7977 8.72534 27.2587 1.18633 17.992 1.18633Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M19.2411 25.8926H15.8921V17.9956H14.2188V15.2746H15.8921V13.6409C15.8921 11.4213 16.8364 10.1006 19.5214 10.1006H21.7561V12.8226H20.3594C19.3141 12.8226 19.2451 13.2029 19.2451 13.9126L19.2404 15.2746H21.7714L21.4751 17.9956H19.2407V25.8929H19.2411V25.8926Z"
      />
    </svg>
  )
}
