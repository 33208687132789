import classNames from "classnames"
import { AnimatePresence, motion, Variants } from "framer-motion"
import Image from "next/legacy/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import { Swiper as SwiperClass } from "swiper/types"

import mobileMenuBg from "@assets/bg/mobile-menu-bg.png"
import { useGlobalFields } from "@contexts/acf-global"
import { useLayoutWrapper } from "@contexts/layout-wrapper"
import { useResponsiveLG, useResponsiveSM } from "@hooks/shared"
import { IconHamburger } from "@icons/hamburger"
import { IconLogo } from "@icons/logo"
import { LogoText } from "@icons/logo-text"
import { useScroll } from "@utils/animation"

import { AppLink, AppSearchInput } from "./shared"

const variantsMenu: Variants = {
  init: { opacity: 0 },
  enter: { opacity: 1, transition: { ease: "easeOut", duration: 0.2 } },
  exit: { opacity: 0, transition: { ease: "easeIn", duration: 0.2 } },
}

interface HeaderProps {
  links?: { label: string; linkType?: "internal" | "external"; link: string }[]
  children?: ReactNode
  empty?: boolean
  headerMobileClass?: string
  swiperMobileHeader?: SwiperMobileHeaderProps
  showLocaleSwitch?: boolean
}

interface SwiperMobileHeaderProps {
  items: JSX.Element[]
  itemsPerView?: number
  activeIndex: number
  changeHandler: (index: number, touched: boolean) => void
}

export const Header = ({
  links,
  children,
  empty,
  headerMobileClass,
  swiperMobileHeader,
  showLocaleSwitch = true,
}: HeaderProps) => {
  const router = useRouter()
  const { headerLinks, expandedHeader, locales } = useGlobalFields()
  const [open, setOpen] = useState(false)
  const isResponsiveLG = useResponsiveLG()
  const isResponsiveSM = useResponsiveSM()
  const [windoWidth, setWindoWidth] = useState(0)
  const swiperRef = useRef<SwiperClass>(null!)
  const activeIndex = swiperMobileHeader?.activeIndex ?? null

  const [minimizeLogo, setMinimizeLogo] = useState(false)
  const layoutWrapper = useLayoutWrapper()
  const scrollY = useScroll({ container: layoutWrapper })

  // build header middle links
  const headerMiddleStuff = empty
    ? null
    : children ||
      (links ?? headerLinks.middleLinks).map(({ label, link, linkType }) => (
        <AppLink
          key={link + label}
          className="header-middle-link"
          href={link}
          external={linkType === "external"}
        >
          {label}
        </AppLink>
      ))

  // build header right links (first link is always the locale switcher)
  const localeSwitcher = (
    <Link
      key="locale-switcher"
      href={router.asPath}
      locale={router.locale === "en" ? "sv" : "en"}
      className="header-right-link app-btn"
    >
      {router.locale === "en"
        ? locales.labelSwedish
        : router.locale === "sv"
        ? locales.labelEnglish
        : "<unk>"}
    </Link>
  )
  const headerRightLinks = [
    showLocaleSwitch ? localeSwitcher : null,
    ...headerLinks.rightLinks.map(({ label, link, linkType }) => (
      <AppLink
        key={link + label}
        className="header-right-link app-btn"
        href={link}
        external={linkType === "external"}
      >
        {label}
      </AppLink>
    )),
  ]

  // build expanded menu links
  const handleCloseMenu = () => setOpen(false)
  const expandedMenuLinks = expandedHeader.links.map(({ label, linkType, link }) => (
    <AppLink
      key={link + label}
      className="expanded-menu-link"
      href={link}
      external={linkType === "external"}
      onClick={handleCloseMenu}
    >
      {label}
    </AppLink>
  ))

  // disallow scrolling when menu is expanded
  useEffect(() => {
    if (open) document.body.classList.add("no-scroll")
    else document.body.classList.remove("no-scroll")
  }, [open])

  useEffect(() => {
    const onResize = () => setWindoWidth(window.innerWidth)
    onResize()
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [])

  let touched = useRef(false)
  const handleSwiperInit = useCallback(
    (swiper: SwiperClass) => {
      swiperRef.current = swiper

      swiper.on("touchStart", () => (touched.current = true))
      swiper.on("transitionEnd", () => (touched.current = false))

      if (swiperMobileHeader) {
        swiper.on("slideChange", () =>
          swiperMobileHeader!.changeHandler(swiper.activeIndex, touched.current)
        )
      }
    },
    [swiperMobileHeader]
  )

  useEffect(() => {
    if (activeIndex !== null && swiperRef.current) swiperRef.current.slideTo(activeIndex)
  }, [activeIndex])

  useEffect(() => {
    if (!isResponsiveLG) return

    scrollY.onChange(val => {
      if (val > 300 && !minimizeLogo) {
        setMinimizeLogo(true)
      } else if (val <= 300 && minimizeLogo) {
        setMinimizeLogo(false)
      }
    })

    return () => scrollY.destroy()
  }, [isResponsiveLG, minimizeLogo, scrollY])

  return (
    <>
      {/* sticky header */}
      <header className="fixed bottom-0 z-[99] w-full py-2 lg:bottom-auto lg:top-0">
        {/* Mobile Menu BG */}
        {!isResponsiveLG && windoWidth > 0 && (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="absolute bottom-0 h-20 w-full"
            >
              <Image src={mobileMenuBg.src} alt="bg" width={windoWidth} height={80} priority />
            </motion.div>
          </AnimatePresence>
        )}

        <div className="wrapper-full relative z-50 flex h-16 items-center justify-between">
          <AppLink href="/">
            <motion.div
              initial={{ width: "100%" }}
              animate={{ width: minimizeLogo ? 50 : "100%" }}
              transition={{ ease: "easeInOut", duration: 0.6 }}
              className="group flex items-center space-x-3 overflow-hidden"
            >
              <IconLogo className="shrink-0 fill-white transition group-hover:opacity-80" />
              {isResponsiveLG && (
                <LogoText className="shrink-0 transition group-hover:opacity-80" />
              )}
            </motion.div>
          </AppLink>

          {/* middle links are hidden when menu is expanded */}
          {isResponsiveLG && !open && (
            <div className="absolute-center flex space-x-9 text-sm text-white/50">
              {headerMiddleStuff}
            </div>
          )}

          {/* search widget is added when menu is expanded */}
          {isResponsiveLG && open && (
            <AppSearchInput
              className="absolute-center w-[496px] lg:w-[400px] xl:w-[496px]"
              placeholder={expandedHeader.searchPlaceholder}
              onSearch={handleCloseMenu}
            />
          )}

          <div className="flex items-center space-x-4 xl:space-x-9">
            {isResponsiveLG && headerRightLinks}
            <IconHamburger open={open} onToggle={() => setOpen(!open)} />
          </div>
        </div>
      </header>

      {/* expanded menu */}
      <AnimatePresence>
        {open && (
          <motion.div
            className="fixed inset-0 z-40 flex flex-col items-center justify-center overflow-y-auto bg-black"
            variants={variantsMenu}
            initial="init"
            animate="enter"
            exit="exit"
          >
            {!isResponsiveLG && (
              <div className=" flex w-full flex-col items-center space-y-5 px-5 pt-7 pb-0">
                {open && (
                  <AppSearchInput
                    // className="absolute-center w-[496px]"
                    className="w-full md:w-2/3"
                    placeholder={
                      isResponsiveSM
                        ? expandedHeader.searchPlaceholder
                        : expandedHeader.searchPlaceholderMobile
                    }
                    onSearch={handleCloseMenu}
                  />
                )}
              </div>
            )}

            {/* Menu Items */}
            <div className="flex w-full flex-1 flex-col items-center justify-center overflow-y-auto">
              {expandedMenuLinks}
            </div>

            {!isResponsiveLG && (
              <div className="flex w-full justify-center space-x-8 pb-20">{headerRightLinks}</div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      {headerMiddleStuff && !isResponsiveLG && !swiperMobileHeader && (
        <div
          className={classNames(
            "header-middle-link-mobile flex snap-x snap-mandatory justify-center space-x-[22px] overflow-y-auto p-6 sm:justify-center",
            headerMobileClass
          )}
        >
          {headerMiddleStuff}
        </div>
      )}

      {/* Swiper menu header mobile */}
      {!isResponsiveSM && swiperMobileHeader && (
        <div className={classNames("header-middle-link-mobile pt-6 pb-3", headerMobileClass)}>
          <Swiper
            // slidesPerView={swiperMobileHeader.itemsPerView ?? 2.75}
            slidesPerView="auto"
            centeredSlides={true}
            loopPreventsSliding={false}
            onSwiper={handleSwiperInit}
            noSwipingSelector="span"
            className="w-full"
          >
            {swiperMobileHeader.items.map((item, index) => (
              <SwiperSlide key={index} className="!w-fit px-3.5 text-center">
                {item}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  )
}
