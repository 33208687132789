import { IconProps } from "./props"

export const IconLogo = ({ className, style }: IconProps) => {
  return (
    <svg className={className} width="41" height="35" viewBox="0 0 41 35" style={style}>
      <g clipPath="url(#clip0_494_2294)">
        <path d="M25.252 30.407l3.453 2.882a7.062 7.062 0 007.728.984 7.002 7.002 0 002.166-1.66l1.929-2.186-.04-.034a3.506 3.506 0 01-4.531-.057l-3.454-2.88c-.694-.6-1.5-1.06-2.374-1.351a7.065 7.065 0 00-5.355.364 7.003 7.003 0 00-2.166 1.66l-1.905 2.169.046.038a3.502 3.502 0 014.503.08M7.984 23.704V7.767a7.885 7.885 0 011.788-4.993 7.996 7.996 0 014.56-2.758V.004a16.014 16.014 0 00-10.214 5.15 15.776 15.776 0 00-4.11 10.611c0 3.92 1.464 7.7 4.11 10.611a16.014 16.014 0 0010.214 5.15v-.064a7.996 7.996 0 01-4.56-2.758 7.885 7.885 0 01-1.788-4.992M31.827 14.079a15.715 15.715 0 00-4.584-9.553A15.943 15.943 0 0017.606 0v.012a7.995 7.995 0 014.56 2.758 7.884 7.884 0 011.787 4.993v7.588a7.963 7.963 0 013.764-1.692 7.991 7.991 0 014.11.416"></path>
      </g>
      <defs>
        <clipPath id="clip0_494_2294">
          <path fill="#fff" d="M0 0H40.526V35H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
