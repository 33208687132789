export const LinkedInLogo = () => {
  return (
    <svg
      className="socialLogo rounded-full fill-white hover:fill-black"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.992 35.984C8.07133 35.984 0 27.913 0 17.992C0 8.071 8.071 0 17.992 0C27.913 0 35.984 8.071 35.984 17.992C35.984 27.913 27.9127 35.984 17.992 35.984ZM17.992 1.18633C8.725 1.18633 1.18633 8.72534 1.18633 17.992C1.18633 27.2587 8.72533 34.7977 17.992 34.7977C27.2587 34.7977 34.7977 27.2587 34.7977 17.992C34.7977 8.72534 27.2587 1.18633 17.992 1.18633Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.481 15.1557H13.564V25.9H10.481V15.1557ZM11.944 13.8117H11.9217C10.8057 13.8117 10.082 12.9903 10.082 11.9503C10.082 10.889 10.827 10.084 11.9654 10.084C13.1027 10.084 13.802 10.887 13.8244 11.9473C13.8244 12.9873 13.1027 13.8117 11.944 13.8117ZM25.8994 25.901H22.4034V20.34C22.4034 18.8847 21.8564 17.8923 20.654 17.8923C19.7344 17.8923 19.2227 18.5617 18.9847 19.209C18.8954 19.44 18.9094 19.763 18.9094 20.087V25.901H15.446C15.446 25.901 15.4907 16.051 15.446 15.1557H18.9094V16.842C19.114 16.1047 20.2207 15.0523 21.9867 15.0523C24.1777 15.0523 25.899 16.5987 25.899 19.9257V25.901H25.8994Z"
      />
    </svg>
  )
}
