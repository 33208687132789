import { scroll } from "@motionone/dom"
import { useMotionValue, Variants } from "framer-motion"
import { RefObject, useEffect } from "react"

export const variantsPostGrid: Variants = {
  initial: { opacity: 0, y: 100 },
  animate: (isInView: boolean) => (isInView ? { opacity: 1, y: [100, -30, 0] } : {}),
}

export const variantsSequenceAnimation: Variants = {
  init: { opacity: 0 },
  enter: { opacity: 1, transition: { duration: 1.5, ease: "easeIn" } },
}

export const useScroll = ({ container }: { container: RefObject<HTMLElement> }) => {
  const scrollY = useMotionValue(0)

  // Equivalent to framer-motion's useScroll but only tracks the Y position
  // Note: do not replace with framer-motion's useScroll because it doesn't work on production builds
  useEffect(
    () => scroll(({ y }) => scrollY.set(y.current), { container: container.current! }),
    [container, scrollY]
  )

  return scrollY
}
