import classNames from "classnames"

import { IconProps } from "./props"

interface IconHamburgerProps extends IconProps {
  open: boolean
  onToggle: () => void
}

export const IconHamburger = ({ open, onToggle }: IconHamburgerProps) => {
  return (
    <button
      className={classNames(
        "relative flex h-[14px] w-[30px] cursor-pointer flex-col justify-between",
        !open && "transition-all hover:h-5"
      )}
      onClick={onToggle}
      tabIndex={-1}
    >
      <div
        className={classNames(
          "w-[30px] border border-white transition",
          open ? "translate-y-1.5 rotate-45" : ""
        )}
      />
      <div
        className={classNames("w-[25px] self-end border border-white transition", {
          "opacity-0": open,
        })}
      />
      <div
        className={classNames(
          "w-[30px] border border-white transition",
          open ? "-translate-y-1.5 -rotate-45" : ""
        )}
      />
    </button>
  )
}
